.db-wrapper {
  width: 100%;
  transition: padding 0.15s ease;
}

@media (width >= 1200px) {
  .db-wrapper.sb-fold {
    padding-left: 4rem;
  }
  .db-wrapper.sb-open {
    padding-left: 16rem;
  }
}

// sidebar css
.sidebar {
  background: #fff;
  border-right: 1px solid var(--cui-border-color) !important;
}
.sidebar-brand {
  background: var(--cui-high-emphasis);
  flex: 0 0 56px;
}
.sidebar-nav .nav-icon {
  color: var(--cui-body-color);
}
.sidebar-nav .nav-link.active .nav-icon {
  color: var(--cui-body-color);
}
.sidebar-nav .nav-link:hover .nav-icon {
  color: var(--cui-body-color);
}
.sidebar-nav .nav-link {
  color: var(--cui-body-color);
}
.sidebar-nav .nav-link.active {
  color: var(--cui-body-color);
  background: var(--cui-gray-200);
}
.sidebar-nav .nav-link:hover {
  color: var(--cui-body-color);
  background-color: var(--cui-gray-200);
}
.sidebar-nav .nav-group.show .nav-group-toggle {
  color: var(--cui-body-color);
}
.sidebar-nav .nav-group.show {
  background-color: var(--cui-tertiary-bg);
  border-top: 1px solid var(--cui-border-color);
  border-bottom: 1px solid var(--cui-border-color);
}
.sidebar-nav .nav-group-toggle::after,
.sidebar-nav .nav-link:hover.nav-group-toggle::after {
  display: block;
  flex: 0 1 15px;
  height: 15px;
  content: "";
  background-image: url("../assets/images/icons/expand-right.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.15s;
}
.sidebar-nav .nav-group.show > .nav-group-toggle::after {
  transform: rotate(90deg);
}
.sidebar-nav .nav-title {
  color: var(--cui-body-color);
}
.sidebar-toggler {
  background: #fff;
  border-top: 1px solid var(--cui-border-color);
  border-right: 1px solid var(--cui-border-color);
}
.sidebar-toggler:hover {
  background: var(--cui-gray-200);
}
.sidebar-toggler::before,
.sidebar-toggler:hover::before {
  display: block;
  width: 4rem;
  height: 3rem;
  content: "";
  background-image: url("../assets/images/icons/expand-right.png");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.15s;
  transform: rotate(180deg) !important;
}
.sidebar-narrow-unfoldable .sidebar-toggler:before {
  transform: rotate(0deg) !important;
}
