// Here you can add other styles

body {
  overflow-x: hidden;
}

// buttons
.btn {
  font-size: 0.75rem;
}

// inputs
.form-control {
  font-size: 0.75rem;
}

// uitilites
.pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1 1 auto;
}

.flex-50per {
  flex: 1 1 50%;
}

hr {
  border-top: 1px solid var(--cui-border-color);
  margin: 12px 0;
  opacity: unset;
}

.p-12 {
  padding: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.ps-12 {
  padding-right: 12pxF;
}

.pe-12 {
  padding-right: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.me-12 {
  margin-right: 12px;
}

.min-h-100 {
  min-height: 100%;
}

.border {
  border: 1px solid var(--cui-border-color);
}

.border-right {
  border-right: 1px solid var(--cui-border-color);
}

.sidebar {
  z-index: 1050 !important;
}
.sidebar-backdrop {
  display: none !important;
}
.my-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

// custom
.tabel-actions-section {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tabel-actions-section > *:not(.qr-dropdown) {
  cursor: pointer;
}

.tabel-actions-section .qr-btn,
.tabel-actions-section .qr-btn:active {
  border: none;
  padding: 0;
}

.header {
  min-height: unset;
}

@media (width >= 768px) {
  .header-search-section {
    border-right: 1px solid var(--cui-border-color);
  }
}

@media (width < 768px) {
  .header-container > div:last-child {
    width: 100%;
  }
}

.breadcrumbs-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.breadcrumbs-wrapper h1,
.breadcrumbs-wrapper > * {
  line-height: 1 !important;
}

.breadcrumbs-wrapper a {
  text-decoration: none;
}

.form-wrapper,
.add-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form-wrapper label {
  font-size: 0.9rem;
  margin-bottom: 6px;
  line-height: 1;
  font-weight: 500;
}

.add-form-wrapper > div {
  display: flex;
  align-items: center;
  gap: 12px;
}

.add-form-wrapper > div label {
  flex: 0 0 30%;
  font-size: 0.9rem;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 500;
}

@media (width < 768px) {
  .add-form-wrapper > div {
    flex-direction: column;
    align-items: start;
    gap: 6px;
  }
  .add-form-wrapper > div label {
    flex: 0 0 100%;
  }
}

.phone-code-input {
  max-width: 85px;
}

.otp-input-section input {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

@media (width > 575px) {
  .login-register-section {
    width: 45%;
  }
  .otp-input-section input {
    width: 3rem !important;
    height: 3rem !important;
  }
}

.card-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.card-head h1,
.card-head h2,
.card-head h3,
.card-head h4,
.card-head h5,
.card-head h6 {
  margin: 0;
  line-height: 1;
}

.my-table table th {
  white-space: nowrap;
}

.my-table table td,
.my-table table th {
  padding: 8px 12px;
}

// mui-x-data-grid-root
.css-az8st9-MuiDataGrid-root {
  font-family: inherit !important;
}

.my-data-table {
  background-color: #fff !important;
  width: 100%;
  height: auto;
}

.my-data-table .MuiDataGrid-root {
  border-width: 0 !important;
}

.my-data-table .MuiToolbar-root p {
  margin: 0;
}

.my-data-table .MuiTablePagination-displayedRows {
  margin: 2.5px 0 0 0 !important;
}

.my-data-table .MuiToolbar-root {
  align-items: center;
}

.my-data-table .MuiTablePagination-select {
  padding: 9px 25px 8px 8px !important;
}

.my-data-table .MuiDataGrid-columnHeader,
.my-data-table .MuiDataGrid-cell {
  outline: none !important;
}

.detail-profile-section {
  display: flex;
  gap: 12px;
}

.detail-profile-info-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 12px;
}

@media (width >= 1200px) {
  .detail-section-row {
    height: 100%;
  }

  .detail-profile-section {
    flex-direction: column;
    height: 100%;
    border-right: 1px solid var(--cui-border-color);
    padding-right: 12px;
    margin-right: 12px;
  }

  .detail-profile-info-item {
    justify-content: space-between;
  }
}

@media (width >= 768px) and (width < 1200px) {
  .detail-profile-section > div {
    flex: 1 1 calc((100% - 16px) / 2);
  }

  .detail-profile-info-item {
    justify-content: start;
  }
}

@media (width < 768px) {
  .detail-profile-section {
    flex-direction: column;
  }

  .detail-profile-info-item {
    justify-content: center;
  }
}

.production-stats {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 12px;
  height: 100%;
  border-right: 1px solid var(--cui-border-color);
}

.production-stats > div:not(:first-child) {
  flex: 1 1;
  max-width: fit-content;
}

.production-stats-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 12px;
}

.production-progress-section {
  display: flex;
  align-items: center;
  gap: 4px 12px;
}

.production-progress-section > p {
  min-width: 80px;
}

.production-progress-section > div {
  flex-grow: 1;
}

@media (width < 1400px) {
  .production-stats {
    border-right: 0;
    border-bottom: 1px solid var(--cui-border-color);
  }

  .production-stats-inner h2 {
    font-size: 28px;
  }
}
@media (width < 768px) {
  .production-stats {
    flex-direction: column;
  }
  .production-stats .vr {
    display: none;
  }
  .production-stats-inner:not(:first-child) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.tracking-list-col {
  position: relative;
}

.tracking-map-list {
  position: absolute;
  inset: 0;
}

@media (width<768px) {
  .tracking-list-col {
    height: 400px;
  }
}

.search-dropdown {
  min-width: 300px;
}
